

export const getSvgs = (color) => ({
  icons: {
    eye: `
      <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 8.18182C18.5534 8.18182 17.166 8.75649 16.1431 9.77942C15.1201 10.8023 14.5455 12.1897 14.5455 13.6364C14.5455 15.083 15.1201 16.4704 16.1431 17.4933C17.166 18.5162 18.5534 19.0909 20 19.0909C21.4466 19.0909 22.834 18.5162 23.8569 17.4933C24.8799 16.4704 25.4545 15.083 25.4545 13.6364C25.4545 12.1897 24.8799 10.8023 23.8569 9.77942C22.834 8.75649 21.4466 8.18182 20 8.18182ZM20 22.7273C17.5889 22.7273 15.2766 21.7695 13.5718 20.0646C11.8669 18.3597 10.9091 16.0474 10.9091 13.6364C10.9091 11.2253 11.8669 8.913 13.5718 7.20812C15.2766 5.50324 17.5889 4.54545 20 4.54545C22.4111 4.54545 24.7234 5.50324 26.4282 7.20812C28.1331 8.913 29.0909 11.2253 29.0909 13.6364C29.0909 16.0474 28.1331 18.3597 26.4282 20.0646C24.7234 21.7695 22.4111 22.7273 20 22.7273ZM20 0C10.9091 0 3.14545 5.65455 0 13.6364C3.14545 21.6182 10.9091 27.2727 20 27.2727C29.0909 27.2727 36.8545 21.6182 40 13.6364C36.8545 5.65455 29.0909 0 20 0Z" fill="${color}"/>
      </svg>

    `,
    new: `
      <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36 0C38.22 0 40 1.78 40 4V28C40 30.22 38.22 32 36 32H4C1.78 32 0 30.22 0 28V4C0 1.78 1.78 0 4 0H36ZM13 22V10H10.5V17L5.5 10H3V22H5.5V15L10.6 22H13ZM23 12.52V10H15V22H23V19.5H18V17.28H23V14.76H18V12.52H23ZM37 20V10H34.5V19H32.26V12H29.76V19H27.5V10H25V20C25 20.5304 25.2107 21.0391 25.5858 21.4142C25.9609 21.7893 26.4696 22 27 22H35C35.5304 22 36.0391 21.7893 36.4142 21.4142C36.7893 21.0391 37 20.5304 37 20Z" fill="${color}"/>
      </svg>

    `,
    archive: `
      <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.1429 0H2.85714C2.09938 0 1.37266 0.301019 0.836838 0.836838C0.301019 1.37266 0 2.09938 0 2.85714V7.14286C0 7.90062 0.301019 8.62734 0.836838 9.16316C1.37266 9.69898 2.09938 10 2.85714 10V25.7143C2.85714 26.472 3.15816 27.1988 3.69398 27.7346C4.2298 28.2704 4.95652 28.5714 5.71429 28.5714H34.2857C35.0435 28.5714 35.7702 28.2704 36.306 27.7346C36.8418 27.1988 37.1429 26.472 37.1429 25.7143V10C37.9006 10 38.6273 9.69898 39.1632 9.16316C39.699 8.62734 40 7.90062 40 7.14286V2.85714C40 2.09938 39.699 1.37266 39.1632 0.836838C38.6273 0.301019 37.9006 0 37.1429 0ZM24.2857 17.1429H15.7143C15.3354 17.1429 14.972 16.9923 14.7041 16.7244C14.4362 16.4565 14.2857 16.0932 14.2857 15.7143C14.2857 15.3354 14.4362 14.972 14.7041 14.7041C14.972 14.4362 15.3354 14.2857 15.7143 14.2857H24.2857C24.6646 14.2857 25.028 14.4362 25.2959 14.7041C25.5638 14.972 25.7143 15.3354 25.7143 15.7143C25.7143 16.0932 25.5638 16.4565 25.2959 16.7244C25.028 16.9923 24.6646 17.1429 24.2857 17.1429ZM37.1429 7.14286H2.85714V2.85714H37.1429V7.14286Z" fill="${color}"/>
      </svg>

    `,
    recomended: `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2H32M5.15789 2V20.3337C5.15789 24.2158 5.83684 25.4843 8.99158 27.4812L13.7505 30.4948C15.3342 31.4983 16.1253 32 17 32C17.8747 32 18.6658 31.4983 20.2495 30.4948L25.01 27.4812C28.1616 25.4826 28.8421 24.2141 28.8421 20.3337V2M20.9474 18.6685H13.0526M20.9474 10.3343H13.0526" stroke="${color}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`

  }
});